import React from "react"
import {
  RequestDialog,
  RequestDialogBox,
  SuccessBox,
} from "../../components/CareerFairRegistrationDialog/Styles"
import {
  SuccessDialogHint,
  SuccessDialogText,
  SuccessDialogTitle,
  SuccessIconImg,
} from "../../pages/InnovationAwardPage/styles"

import SuccessIcon from "../../images/innovation-awards/modal-success.png"

export type Props = {
  onClose: () => void
  open: boolean
}

const Dialog = ({ open, onClose }: Props) => {
  return (
    <RequestDialog
      maxWidth={"md"}
      open={open}
      onClose={onClose}
      className={"ConnectionRequestDialog"}
    >
      <RequestDialogBox>
        <SuccessBox>
          <SuccessIconImg src={SuccessIcon} alt={"success"} />
          <SuccessDialogTitle variant="h2">Congratulations</SuccessDialogTitle>
          <SuccessDialogText>
            <span>
              You’ve successfully registered to the Spectacular Firm Career
              Showcase.
            </span>
          </SuccessDialogText>
          <SuccessDialogHint>
            Keep an eye out for a confirmation email regarding next steps.
          </SuccessDialogHint>
        </SuccessBox>
      </RequestDialogBox>
    </RequestDialog>
  )
}

export default Dialog
