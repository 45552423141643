import { Box, Typography } from "@material-ui/core"
import styled from "styled-components"
import theme from "../../theme"
import ulIcon from "../../images/innovation-awards/ul-icon.png"

export const MainContainer = styled(Box)<{ $isAuthenticated: boolean }>`
  margin-top: ${({ $isAuthenticated }) => ($isAuthenticated ? "-40px" : "")};
  margin-bottom: -64px;
  background: #ffffff;

  ${theme.breakpoints.down("xs")} {
    margin-top: 0;
    margin-bottom: -20px;
  }
`

export const HeaderBoxMobile = styled(Box)`
  display: none;

  ${theme.breakpoints.down("sm")} {
    display: flex;
    justify-content: center;
  }

  ${theme.breakpoints.down("xs")} {
    margin-top: 48px;
    && img {
      width: 100%;
    }
  }
`

export const HeaderBox = styled(Box)`
  width: 100%;
  background-repeat: no-repeat;
  background-size: auto, cover;
  background-position: right, center;
  display: flex;
  justify-content: center;
  height: 550px;
  overflow: hidden;
  border-bottom: 2px solid #000000;

  ${theme.breakpoints.down("sm")} {
    height: auto;
    align-items: center;
    box-sizing: border-box;

    flex-flow: column;
    align-items: center;
  }

  ${theme.breakpoints.down("xs")} {
    padding: 20px;
  }
`

export const HeaderDescription = styled(Typography)`
  padding-top: 32px;
  max-width: 710px;
  width: 710px;
  color: #2d2d2d;
  font-size: 20px;
  line-height: 31.2px;

  ${theme.breakpoints.down("sm")} {
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    padding-top: 32px;
    max-width: 100%;
    text-align: left;
  }
`

export const HeaderInnerBox = styled(Box)`
  max-width: 1440px;
  box-sizing: border-box;
  padding: 0 185px;
  width: 100%;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 1420px) {
    padding: 0 80px;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 64px 20px;
  }

  ${theme.breakpoints.down("xs")} {
    flex-flow: column;
    padding: 0;
  }
`

export const HeaderFlexBox = styled(Box)`
  display: flex;
  justify-content: space-between;
`

export const LottieBox = styled(Box)`
  margin-left: 120px;
  ${theme.breakpoints.down("sm")} {
    display: none;
  }
`

export const HeaderContainer = styled(Box)`
  width: 100%;

  ${theme.breakpoints.down("xs")} {
    text-align: center;
  }
`

export const StyledLogo = styled.img`
  @media only screen and (max-width: 1075px) {
    max-width: 560px;
  }

  ${theme.breakpoints.down("sm")} {
    width: 100%;
    max-width: 560px;
  }

  ${theme.breakpoints.down("xs")} {
    width: 100%;
    max-width: 100%;
  }
`

export const RegistrationBox = styled(Box)`
  display: flex;
  justify-content: center;
  background: #ffffff;
  box-sizing: border-box;
  border-bottom: 2px solid #2d2d2d;

  ${theme.breakpoints.down("xs")} {
    background: #ffffff;
  }
`

export const RegistrationContainer = styled(Box)`
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box;
  padding: 0 185px;

  @media only screen and (max-width: 1420px) {
    padding: 0 80px;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 0;
  }
`

export const RegistrationTitle = styled(Typography)`
  font-size: 48px;
  line-height: 36px;
  padding-top: 48px;
  padding-bottom: 48px;
  box-sizing: border-box;

  ${theme.breakpoints.down("sm")} {
    padding-top: 32px;
    padding-bottom: 32px;
    font-size: 32px;
  }

  ${theme.breakpoints.down("xs")} {
    padding: 44px 20px 42px;
  }
`

export const RegistrationFlexBox = styled(Box)`
  display: flex;

  ${theme.breakpoints.down("xs")} {
    display: initial;
  }
`

export const BenefitsList = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  padding: 64px 0;

  ${theme.breakpoints.down("sm")} {
    flex-flow: column;
    align-items: center;
    gap: 20px;
  }
`

export const BenefitsItem = styled(Box)`
  display: flex;
  flex-flow: column;
  border: 1px solid rgba(0, 0, 0, 1);
  border-radius: 6px;
  width: 230px;
  height: 200px;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  && img {
    height: 54px;
  }
`

export const FirmBox = styled(Box)`
  padding-bottom: 44px;
`

export const FirmList = styled(Box)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  ${theme.breakpoints.down("sm")} {
    gap: 20px;
    padding: 0 20px;
    box-sizing: border-box;

    && img {
      width: 150px;
    }
  }
`

export const FirmItem = styled(Box)`
  padding: 20px 0;
  flex-basis: 30%;
  box-sizing: border-box;

  ${theme.breakpoints.down("sm")} {
    padding: 0;
  }
`

export const TalkBox = styled(Box)`
  width: 100%;
  text-align: center;
  padding-bottom: 64px;
`

export const TalkText = styled(Typography)`
  font-size: 32px;
`

export const TalkButtonBox = styled(Box)`
  margin-top: 32px;
`

export const RegistrationLeftBox = styled(Box)`
  box-sizing: border-box;
  border-left: 2px solid #2d2d2d;
  border-right: 2px solid #2d2d2d;
  width: 100%;
  padding: 32px 40px 64px;

  ${theme.breakpoints.down("sm")} {
    padding: 32px 20px 64px;
  }

  ${theme.breakpoints.down("xs")} {
    border: 0;
    padding: 0 20px 48px;
    margin-top: 32px;
  }
`

export const RegistrationRightBox = styled(Box)`
  box-sizing: border-box;
  border-right: 2px solid #2d2d2d;
  width: 100%;
  padding: 40px;

  ${theme.breakpoints.down("sm")} {
    padding: 32px 20px 64px;
  }

  ${theme.breakpoints.down("xs")} {
    border: 0;
    padding: 32px 20px 0;
    margin-top: 32px;
    padding-bottom: 48px;
    border-top: 2px solid #2d2d2d;
  }
`

export const RegistrationInnerTitle = styled(Typography)`
  font-size: 40px;

  && img {
    margin-right: 10px;
  }

  ${theme.breakpoints.down("sm")} {
    font-size: 32px;

    && img {
      height: 24px;
      margin-right: 5px;
    }
  }
`

export const RegistrationSubTitle = styled(Typography)`
  font-family: Public-Sans-Medium;
  font-size: 24px;

  && a {
    color: #2d2d2d;
    font-family: Public-Sans-Bold;
  }

  ${theme.breakpoints.down("sm")} {
    font-size: 20px;
  }

  ${theme.breakpoints.down("xs")} {
    font-size: 16px;
    text-align: center;
  }
`

export const RegistrationItem = styled(Box)`
  background: url(${ulIcon}) no-repeat;
  background-position-y: 4px;
  padding-left: 36px;
  font-size: 20px;
  line-height: 24px;
  margin-top: 17px;

  && img {
    vertical-align: middle;
  }

  && ul {
    margin: 0;
    padding: 0;
    list-style-position: inside;
  }

  && li {
    margin-top: 8px;
  }

  ${theme.breakpoints.down("sm")} {
    font-size: 16px;
  }
`

export const RegistrationItemNoIcon = styled(Box)`
  background-position-y: 4px;
  padding-left: 36px;
  font-size: 20px;
  line-height: 24px;
  margin-top: 30px;

  ${theme.breakpoints.down("sm")} {
    font-size: 16px;
  }
`

export const RegistrationDivider = styled(Box)`
  border-top: 1px solid #c4c4c4;
  width: 100%;
  margin: 48px 0;

  ${theme.breakpoints.down("sm")} {
    margin: 32px 0;
  }

  ${theme.breakpoints.down("xs")} {
    margin: 20px 0;
  }
`

export const RegistrationButtonBox = styled(Box)`
  margin-top: 32px;

  && a {
    text-decoration: none;
  }

  ${theme.breakpoints.down("xs")} {
    margin-top: 24px;
    && button {
      width: 100% !important;
    }
  }
`

export const IconBox = styled(Box)`
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 32px;
  padding-top: 32px;
`

export const StyledIcon = styled.img`
  height: 48px;
`

export const IconTitle = styled(Typography)`
  font-family: FreightDispProBook;
  font-size: 40px;
`

export const IconButtonBox = styled(Box)`
  ${theme.breakpoints.down("xs")} {
    && button {
      width: 100% !important;
    }
  }
`

export const HowItWorksBox = styled(Box)`
  display: flex;
  justify-content: center;
  background: #2d2d2d;
  box-sizing: border-box;
  border-bottom: 2px solid #fff;
`

export const HowItWorksContainer = styled(Box)`
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box;
  padding: 0 185px;

  @media only screen and (max-width: 1420px) {
    padding: 0 80px;
  }

  ${theme.breakpoints.down("sm")} {
    padding: 0;
  }
`

export const HowItWorksTitle = styled(Typography)`
  font-size: 48px;
  line-height: 36px;
  padding-top: 48px;
  padding-bottom: 52px;
  box-sizing: border-box;
  color: #fff;

  ${theme.breakpoints.down("sm")} {
    padding-top: 32px;
    padding-bottom: 32px;
    font-size: 32px;
  }

  ${theme.breakpoints.down("xs")} {
    padding: 44px 20px 42px;
  }
`

export const HowItWorksFlexBox = styled(Box)`
  display: flex;

  ${theme.breakpoints.down("xs")} {
    display: initial;
  }
`

export const HowItWorksLeftBox = styled(Box)`
  box-sizing: border-box;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  width: 100%;
  padding: 56px 40px 88px;

  ${theme.breakpoints.down("sm")} {
    padding: 32px 20px 64px;
  }

  ${theme.breakpoints.down("xs")} {
    border: 0;
    padding: 0 20px 48px;
    margin-top: 32px;
  }
`

export const HowItWorksText = styled(Typography)`
  color: #fff;
  font-size: 20px;
  line-height: 28px;

  && span {
    color: #3057e1;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  && ul {
    margin: 0;
    margin-top: 8px;
  }

  ${theme.breakpoints.down("xs")} {
    font-size: 16px;
  }
`

export const RegisterText = styled(Typography)`
  color: #2d2d2d;
  font-size: 20px;
  line-height: 28px;

  && span {
    cursor: pointer;
    color: #1377d4;

    &:hover {
      text-decoration: underline;
    }
  }

  ${theme.breakpoints.down("xs")} {
    font-size: 16px;
  }
`

export const RegisterButtonBox = styled(Box)`
  margin-top: 32px;
  ${theme.breakpoints.down("xs")} {
    && button {
      width: 100% !important;
    }
  }
`

export const AIABox = styled(Box)`
  display: flex;
  align-items: center;

  && img {
    width: 170px;
  }

  ${theme.breakpoints.down("xs")} {
    flex-flow: column;
    gap: 12px;
    margin-top: 12px;

    && img {
      width: 132px;
    }
  }
`

export const AIAText = styled(Box)`
  font-size: 24px;

  ${theme.breakpoints.down("xs")} {
    font-size: 18px;
  }
`

export const AIAImage = styled(Box)``
