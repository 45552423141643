import { Auth0ContextInterface } from "@auth0/auth0-react"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { useApi } from "../hooks/useApi"

//#region api
type CreateEventRegistrationPayload = {
  auth: Auth0ContextInterface
  campaign: string
  additionalData?: string[]
}
export const createEventRegistration = createAsyncThunk<
  any,
  CreateEventRegistrationPayload
>("/eventRegistration/create", async ({ auth, campaign, additionalData }) => {
  const data = {
    additionalData: JSON.stringify(additionalData as string[]),
    origin: window.location.origin,
    campaign,
  }

  return useApi(auth, "/event-registrations", {
    method: "POST",
    body: JSON.stringify(data),
  }).then((res) => res.text())
})
//#endregion
