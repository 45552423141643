import { useAuth0 } from "@auth0/auth0-react"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import View from "./View"
import { Helmet } from "react-helmet"
import Logo from "../../images/career-showcase-logo.svg"
import RouteChangeTracker from "../../components/RouteChangeTracker"
import { useAppDispatch } from "../../redux/configureStore"
import { createEventRegistration } from "../../redux/eventRegistration"
import { toggleDialog } from "../../redux/config"

export interface InnovationAwardsCoAuthor {
  name: string
  profileLink: string
}

export interface InnovationAwardsForm {
  name: string
  category: string
  email: string
  projectLink: string
  projectTitle: string
  updated: boolean
  singleAuthor: boolean
  groupProject: boolean
  coAuthors?: InnovationAwardsCoAuthor[]
}

const FirmShowcase = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const history = useHistory()
  const { isAuthenticated } = useAuth0()
  const dispatch = useAppDispatch()
  const auth = useAuth0()

  // @ts-ignore
  window.prerenderReady = true

  useEffect(() => {
    setTimeout(() => {
      document
        .getElementById(history.location.hash.slice(1))
        ?.scrollIntoView({ behavior: "smooth" })
    }, 500)
  }, [history.location.hash])

  const handleSubmit = async () => {
    if (!auth.isAuthenticated) {
      await dispatch(toggleDialog({ opened: true }))
      return
    }

    try {
      const { type } = await dispatch(
        createEventRegistration({
          auth,
          campaign: "firm-career-showcase-november-2024",
        })
      )
      if (createEventRegistration.fulfilled.type === type) {
        setIsDialogOpen(true)
        return Promise.resolve()
      } else {
        return Promise.reject()
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  return (
    <>
      <RouteChangeTracker
        screenTitle={`Firm Career Showcase`}
        classTitle="FirmCareerShowcase"
      />
      <Helmet>
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={Logo} />
        <meta
          property="og:title"
          content={`Firm Career Showcase 2024 - Spectacular`}
        />
        <meta
          property="og:description"
          content={`Join Spectacular's Firm Career Showcase live on Monday, November 4th where the nation’s top design firms will connect directly with thousands of Architects & designers across the country. Fill your pipeline with the most talented ‘go-getters’ in the country by inspiring the next generation of starchitects and celebrating what makes your firm unique. Register today to secure top talent for your firm’s future.`}
        />
        <meta property="og:site_name" content={"Spectacular"} />
        <meta property="og:locale" content="en_US" />
        <meta
          name="title"
          content={`Firm Career Showcase 2024 - Spectacular`}
        />
        <meta
          name="description"
          content={`Join Spectacular's Firm Career Showcase live on Monday, November 4th where the nation’s top design firms will connect directly with thousands of Architects & designers across the country. Fill your pipeline with the most talented ‘go-getters’ in the country by inspiring the next generation of starchitects and celebrating what makes your firm unique. Register today to secure top talent for your firm’s future.`}
        />
        <title>Firm Career Showcase 2024 - Spectacular</title>
      </Helmet>
      <View
        isAuthenticated={isAuthenticated}
        onSubmit={handleSubmit}
        isDialogOpen={isDialogOpen}
        onCloseDialog={() => setIsDialogOpen(false)}
      />
    </>
  )
}

export default FirmShowcase
