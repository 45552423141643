import React from "react"

import { Box } from "@material-ui/core"

import Logo from "../../images/career-showcase-logo.svg"
import WebinarImage from "../../images/career-fair/webinar-image.svg"

import {
  HeaderBox,
  HeaderContainer,
  HeaderInnerBox,
  StyledLogo,
  HeaderDescription,
  HeaderFlexBox,
  LottieBox,
} from "./styles"

const Header = () => {
  return (
    <HeaderBox>
      <HeaderInnerBox>
        <HeaderContainer>
          <HeaderFlexBox>
            <Box>
              <Box>
                <StyledLogo src={Logo} alt={"Logo"} />
              </Box>
              <HeaderDescription>
                Join Spectacular's Firm Career Showcase live on Monday, November
                4th where the nation’s top design firms will connect directly
                with thousands of Architects & designers across the country. 
                <br />
                <br />
                Fill your pipeline with the most talented ‘go-getters’ in the
                country by inspiring the next generation of starchitects and
                celebrating what makes your firm unique. Register today to
                secure top talent for your firm’s future.
              </HeaderDescription>
            </Box>
            <LottieBox>
              <img src={WebinarImage} alt="Webinar" />
            </LottieBox>
          </HeaderFlexBox>
        </HeaderContainer>
      </HeaderInnerBox>
    </HeaderBox>
  )
}

export default Header
