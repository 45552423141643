import React from "react"

import Header from "./Header"

import {
  BenefitsItem,
  BenefitsList,
  FirmBox,
  FirmItem,
  FirmList,
  HowItWorksBox,
  HowItWorksContainer,
  HowItWorksFlexBox,
  HowItWorksLeftBox,
  HowItWorksText,
  HowItWorksTitle,
  IconBox,
  IconButtonBox,
  IconTitle,
  MainContainer,
  RegistrationBox,
  RegistrationContainer,
  RegistrationFlexBox,
  RegistrationLeftBox,
  RegistrationRightBox,
  RegistrationTitle,
  StyledIcon,
  TalkBox,
  TalkButtonBox,
  TalkText,
} from "./styles"

import { Button } from "../../components"

import FirmIcon from "../../images/career-fair/icon-firm.svg"
import IndividualIcon from "../../images/career-fair/icon-individual.svg"

import VisibilityIcon from "../../images/icon-visibility.svg"
import PersonIcon from "../../images/icon-person-star.svg"

import Firm1 from "../../images/career-fair/firm-image-1.jpg"
import Firm2 from "../../images/career-fair/firm-image-2.jpg"
import Firm3 from "../../images/career-fair/firm-image-3.jpg"
import Firm4 from "../../images/career-fair/firm-image-4.jpg"
import Firm5 from "../../images/career-fair/firm-image-5.jpg"
import Firm6 from "../../images/career-fair/firm-image-6.jpg"
import Firm7 from "../../images/career-fair/firm-image-7.jpg"
import Firm8 from "../../images/career-fair/firm-image-8.jpg"
import Firm9 from "../../images/career-fair/firm-image-9.jpg"
import { Link } from "react-router-dom"
import Dialog from "./Dialog"

interface Props {
  isAuthenticated: boolean
  onSubmit: () => void
  isDialogOpen: boolean
  onCloseDialog: () => void
}

const View = ({
  isAuthenticated,
  onSubmit,
  isDialogOpen,
  onCloseDialog,
}: Props) => {
  const goToForm = () => {
    document
      .getElementById("registrationArea")
      ?.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <MainContainer $isAuthenticated={isAuthenticated}>
      <Header />
      <RegistrationBox>
        <RegistrationContainer>
          <RegistrationTitle variant="h2">
            This is your chance to:
          </RegistrationTitle>
        </RegistrationContainer>
      </RegistrationBox>
      <RegistrationBox>
        <RegistrationContainer>
          <RegistrationFlexBox>
            <BenefitsList>
              <BenefitsItem>
                <img
                  src={VisibilityIcon}
                  alt="Instantly increase your firm's visibility"
                />
                Instantly increase your firm's visibility
              </BenefitsItem>
              <BenefitsItem>
                <img src={FirmIcon} alt="Share what makes your firm unique" />
                Share what makes your firm unique
              </BenefitsItem>
              <BenefitsItem>
                <img
                  src={PersonIcon}
                  alt="Build a pipeline of top talent for your future"
                />
                Build a pipeline of top talent for your future
              </BenefitsItem>
            </BenefitsList>
          </RegistrationFlexBox>
        </RegistrationContainer>
      </RegistrationBox>

      <HowItWorksBox>
        <HowItWorksContainer>
          <HowItWorksTitle variant="h2">How it Works</HowItWorksTitle>
        </HowItWorksContainer>
      </HowItWorksBox>

      <HowItWorksBox>
        <HowItWorksContainer>
          <HowItWorksFlexBox>
            <HowItWorksLeftBox>
              <HowItWorksText>
                On Monday, November 4th Spectacular will host an exclusive
                live-webinar, offering a select few firms the opportunity to
                present directly to thousands of Architects & designers across
                the country.
                <ul>
                  <li>Each firm will have 10 minutes to present</li>
                  <li>
                    Limited Availability.{" "}
                    <span onClick={goToForm}>Register now!</span>
                  </li>
                </ul>
              </HowItWorksText>
            </HowItWorksLeftBox>
          </HowItWorksFlexBox>
        </HowItWorksContainer>
      </HowItWorksBox>

      <RegistrationBox>
        <RegistrationContainer>
          <RegistrationTitle variant="h2">Industry Partners:</RegistrationTitle>
          <FirmBox>
            <FirmList>
              <FirmItem>
                <Link to={"/sp/goettsch-partners"}>
                  <img src={Firm1} alt="Goettsch Partners" />
                </Link>
              </FirmItem>
              <FirmItem>
                <Link to={"/sp/park-fowler-plus"}>
                  <img src={Firm2} alt="ParkFowler Plus" />
                </Link>
              </FirmItem>
              <FirmItem>
                <Link to={"/sp/neumannsmith-architecture"}>
                  <img src={Firm3} alt="Neumann/Smith Architecture" />
                </Link>
              </FirmItem>
              <FirmItem>
                <Link to={"/sp/officeuntitled-ou-4191"}>
                  <img src={Firm4} alt="OFFICEUNTITLED (OU)" />
                </Link>
              </FirmItem>
              <FirmItem>
                <Link to={"/sp/anacapa-architecture"}>
                  <img src={Firm5} alt="Anacapa Architecture" />
                </Link>
              </FirmItem>
              <FirmItem>
                <img src={Firm6} alt="" />
              </FirmItem>
              <FirmItem>
                <Link to={"/sp/stl_architects"}>
                  <img src={Firm7} alt="STL Architects" />
                </Link>
              </FirmItem>
              <FirmItem>
                <img src={Firm8} alt="" />
              </FirmItem>
              <FirmItem>
                <img src={Firm9} alt="" />
              </FirmItem>
            </FirmList>
          </FirmBox>

          <TalkBox>
            <TalkText>
              Interested in becoming an exclusive sponsorship partner?
            </TalkText>
            <TalkButtonBox>
              <Button
                children={"Let's talk"}
                color="secondary"
                style={{ width: "298px" }}
                onClick={() => {
                  window.location.href = "mailto:sethkopka@blackspectacles.com"
                }}
              />
            </TalkButtonBox>
          </TalkBox>
        </RegistrationContainer>
      </RegistrationBox>

      <RegistrationBox id={"registrationArea"}>
        <RegistrationContainer>
          <RegistrationTitle variant="h2">Register here:</RegistrationTitle>
        </RegistrationContainer>
      </RegistrationBox>

      <RegistrationBox>
        <RegistrationContainer>
          <RegistrationFlexBox>
            <RegistrationLeftBox>
              <IconBox>
                <StyledIcon src={IndividualIcon} alt="Individual" />
                <IconTitle>Individual</IconTitle>
                <IconButtonBox>
                  <Button
                    children={"Register as attendee"}
                    color="secondary"
                    style={{ width: "298px" }}
                    onClick={onSubmit}
                  />
                </IconButtonBox>
              </IconBox>
            </RegistrationLeftBox>
            <RegistrationRightBox>
              <IconBox>
                <StyledIcon src={FirmIcon} alt="Firm" />
                <IconTitle>Firm</IconTitle>
                <IconButtonBox>
                  <Button
                    children={"Present your firm"}
                    color="secondary"
                    style={{ width: "298px" }}
                    onClick={() => {
                      window.location.href =
                        "mailto:sethkopka@blackspectacles.com"
                    }}
                  />
                </IconButtonBox>
              </IconBox>
            </RegistrationRightBox>
          </RegistrationFlexBox>
        </RegistrationContainer>
      </RegistrationBox>
      <Dialog open={isDialogOpen} onClose={onCloseDialog} />
    </MainContainer>
  )
}

export default View
