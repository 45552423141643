import React from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import * as RouteConstants from "../constants/routes"
import Navbar from "../components/Navbar"
import HomePage from "../pages/HomePage"
import AboutPage from "../pages/AboutPage"
import MemberBenefitsPage from "../pages/MemberBenefitsPage"
import PressPage from "../pages/PressPage"
import TermsUsePage from "../pages/TermsUsePage"
import CookiePolicyPage from "../pages/CookiePolicyPage"
import DisclaimerPage from "../pages/DisclaimerPage"
import PrivacyPolicyPage from "../pages/PrivacyPolicyPage"
import ProtectedRoute from "../components/ProtectedRoute"
import ProjectWizardPage from "../pages/ProjectWizardPage"
import NominationPage from "../pages/Nominations"
import ProjectsPage from "../pages/ProjectsPage"
import IndividualsGroupsPage from "../pages/IndividualsGroupsPage"
import ProjectDetailPage from "../pages/ProjectDetailPage"
import ProjectEditPage from "../pages/ProjectEditPage"
import ProfileEditPage from "../pages/ProfileEditPage"
import OnboardingCheckPage from "../pages/OnboardingCheckPage"
import ProfileDetailPage from "../pages/ProfileDetailPage"
import ExperienceEditPage from "../pages/ExperienceEditPage"
import JobPositionNewPage from "../pages/JobPositionNewPage"
import JobPositionEditPage from "../pages/JobPositionEditPage"
import EducationNewPage from "../pages/EducationNewPage"
import EducationEditPage from "../pages/EducationEditPage"
import NotificationsPage from "../pages/NotificationsPage"
import ConnectionRequestsPage from "../pages/ConnectionRequestsPage"
import InnovationAwardPage from "../pages/InnovationAwardPage"
import CareerFairPage from "../pages/CareerFairPage"
import UnsubscribePage from "../pages/UnsubscribePage"
import NetworkPage from "../pages/NetworkPage"
import PostPage from "../pages/PostPage"
import WeekendCompetitionPage from "../pages/WeekendCompetitionPage"
import DeleteAccountPage from "../pages/DeleteAccountPage"
import StudentAmbassadorPage from "../pages/StudentAmbassadorPage"
import JobsPage from "../pages/JobsPage"
import JobListingWizardPage from "../pages/JobListingWizardPage"
import DashboardPage from "../pages/DashboardPage"
import JobListingDetailsPage from "../pages/JobListingDetailsPage"
import StudioAwardPage from "../pages/StudioAwardPage"
import InternExpoPage from "../pages/InternExpoPage"
import PromotionWizardPage from "../pages/PromotionWizardPage"
import PricingPage from "../pages/PricingPage"
import NewsPage from "../pages/NewsPage"
import FirmShowcase from "../pages/FirmShowcasePage"

export const UserRoutes = () => (
  <>
    <Navbar />
    <div className="main-content">
      <Switch>
        <ProtectedRoute path="/unsub" component={UnsubscribePage} />
        <ProtectedRoute path="/projects/new" component={ProjectWizardPage} />
        <ProtectedRoute path="/projects/:id/edit" component={ProjectEditPage} />
        <ProtectedRoute
          path={RouteConstants.PROFILE_CONTAINER + "/:username/projects/:id"}
          component={ProjectDetailPage}
        />
        <ProtectedRoute path="/projects" component={ProjectsPage} />
        <ProtectedRoute exact path="/posts/:id" component={PostPage} />
        <ProtectedRoute exact path="/news/:id" component={NewsPage} />
        <ProtectedRoute
          path="/profiles"
          exact
          component={IndividualsGroupsPage}
        />
        <ProtectedRoute exact path="/" component={HomePage} />
        <ProtectedRoute path="/featured_projects" component={NominationPage} />
        <ProtectedRoute
          exact
          path="/experiences/edit"
          component={ExperienceEditPage}
        />
        <ProtectedRoute
          exact
          path="/job_positions/new"
          component={JobPositionNewPage}
        />
        <ProtectedRoute
          exact
          path="/job_positions/:id/edit"
          component={JobPositionEditPage}
        />
        <ProtectedRoute
          exact
          path="/educations/new"
          component={EducationNewPage}
        />
        <ProtectedRoute
          exact
          path="/educations/:id/edit"
          component={EducationEditPage}
        />
        <Route
          path="/authentication_callback"
          component={OnboardingCheckPage}
        />
        <ProtectedRoute
          exact
          path="/profile/edit"
          component={ProfileEditPage}
        />
        <ProtectedRoute exact path="/jobs" component={JobsPage} />
        <ProtectedRoute
          typesAllowed={["group"]}
          path="/job_listings/new"
          component={JobListingWizardPage}
        />
        <ProtectedRoute
          exact
          typesAllowed={["group"]}
          path="/job_listings/:id/:type"
          component={JobListingWizardPage}
        />
        <ProtectedRoute
          path={
            RouteConstants.PROFILE_CONTAINER + "/:username/job_listings/:id"
          }
          component={JobListingDetailsPage}
        />
        <ProtectedRoute
          exact
          typesAllowed={["group"]}
          path="/job_listings/:id/promotion/:operation"
          component={PromotionWizardPage}
        />
        <ProtectedRoute exact path="/pricing" component={PricingPage} />
        <ProtectedRoute
          path={RouteConstants.PROFILE_CONTAINER + "/:username/:tab?"}
          component={ProfileDetailPage}
        />
        <ProtectedRoute path="/about" component={AboutPage} />
        <ProtectedRoute path="/press" component={PressPage} />

        <ProtectedRoute
          path={"/innovation-awards/:slug"}
          component={InnovationAwardPage}
        />

        <ProtectedRoute
          path={"/weekend-competition/:slug"}
          component={WeekendCompetitionPage}
        />

        <ProtectedRoute
          path={"/studio-awards/:slug"}
          component={StudioAwardPage}
        />

        <ProtectedRoute
          path="/student-ambassador"
          component={StudentAmbassadorPage}
        />
        <ProtectedRoute path="/career-fair" component={CareerFairPage} />
        <ProtectedRoute path="/intern-expo" component={InternExpoPage} />
        <ProtectedRoute path="/firm-career-showcase" component={FirmShowcase} />
        <ProtectedRoute path="/firm-spotlight" component={CareerFairPage} />
        <ProtectedRoute
          path="/member_benefits"
          component={MemberBenefitsPage}
        />
        <ProtectedRoute path="/terms_use" component={TermsUsePage} />
        <ProtectedRoute path="/cookie_policy" component={CookiePolicyPage} />
        <ProtectedRoute path="/disclaimer" component={DisclaimerPage} />
        <ProtectedRoute path="/privacy_policy" component={PrivacyPolicyPage} />
        <ProtectedRoute path="/notifications" component={NotificationsPage} />
        <ProtectedRoute path="/network/:tab?/:type?" component={NetworkPage} />
        <ProtectedRoute
          path="/connection-requests"
          component={ConnectionRequestsPage}
        />
        <ProtectedRoute path="/delete_account" component={DeleteAccountPage} />
        <ProtectedRoute
          typesAllowed={["group"]}
          path="/dashboard"
          component={DashboardPage}
        />

        <Route path={RouteConstants.PROFILE_CONTAINER} exact>
          <Redirect to="/" />
        </Route>
        <Route path="/healthz" exact>
          <p>success</p>
        </Route>

        <Route path="/*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </div>
  </>
)

export default UserRoutes
